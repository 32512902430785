import React, { useState, useEffect } from "react";
import { Navigation } from "./navigation";
import { Header } from "./header";
import { About } from "./about";
import { Services } from "./services";
import { Gallery } from "./gallery";
import { InceInsaat } from "./InceInsaat";
import { Contact } from "./contact";
import JsonData from "../data/data.json";
import "../App.css";

export const Home = () => {
    const [landingPageData, setLandingPageData] = useState({});
    useEffect(() => {
      setLandingPageData(JsonData);
    }, []);
  return (
    <div>
    <Navigation />
    <Header data={landingPageData.Header} />
    <About data={landingPageData.About} />
    <Services data={landingPageData.Services} />
    <Gallery data={landingPageData.HomeKaba} />
    <InceInsaat data={landingPageData.HomeInce} />
    <Contact data={landingPageData.Contact} />
  </div>
  );
};
export default Home;