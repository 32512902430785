import React from 'react';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import CircularProgress from "@mui/material/CircularProgress";

// Varsayılan ikonu ayarlayın
const defaultIcon = new L.Icon({
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
  shadowSize: [41, 41]
});

export const MapComponent = ({data}) => {
  if (!data) {
    return (
      <div className="text-center" style={{fontSize:"3rem"}}><CircularProgress color="inherit" /></div>        
    );
  }

  const lat = parseFloat(data.sellers.lat);
  const lon = parseFloat(data.sellers.lon);


  const mapStyles = { height: "400px", width: "100%" };
  const defaultCenter = [lat, lon];


  return (
    <MapContainer center={defaultCenter} zoom={13} style={mapStyles}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={defaultCenter} icon={defaultIcon} />
    </MapContainer>
  );
}
