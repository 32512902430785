import React, { useState, useEffect } from "react";
import { Navigation } from "./navigation";
import { Contact } from "./contact";
import JsonData from "../data/data.json";
import { ListProduct } from "./listProduct";
import { ScrollToTop } from "./scrollToTop";

export const Product = (props) => {
    const [landingPageData, setLandingPageData] = useState({});
    useEffect(() => {
      setLandingPageData(JsonData);
    }, []);



  return (
    <div>
    <nav id="menu" className="navbar navbar-default navbar-fixed-top" style={{background:'linear-gradient(to right, #424563 0%, #5ca9fb 100%'}}>
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand page-scroll" href="/" style={{ color:'white'}}>
          malzex.com
          </a>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li className="active">
              <a href="/product" className="page-scroll" style={{ color:'white'}}>
                Ürünler
              </a>
            </li>
            <li>
              <a href="/#about" className="page-scroll" style={{ color:'white'}}>
                Hakkında
              </a>
            </li>
            <li>
              <a href="/#services" className="page-scroll" style={{ color:'white'}}>
                Hizmetler
              </a>
            </li>
            <li>
              <a href="#contact" className="page-scroll" style={{ color:'white'}}>
                İletişim
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
    <ListProduct/>
    <br/>
    {/* <InceInsaat data={landingPageData.InceInsaat} /> */}
    <ScrollToTop />
    <Contact data={landingPageData.Contact} />
    </div>
  );
};
export default Product;