import React, { useState, useEffect } from "react";
import { Typography } from '@mui/material';
export const  ProductCard = (props) => {

  const productId = props.product.productsId;
  const sellersId = props.product.sellersId;
  const productDetailsId = props.product.id;

  function encodeParams(sellersId, productId) {
    const encodedSellersId = btoa(sellersId);
    const encodedProductId = btoa(productId);
    const encodedProductDetailsId = btoa(productDetailsId);
    return `${encodedSellersId}/${encodedProductId}/${encodedProductDetailsId}`;
}
function handleDetailsClick() {
    const encodedParams = encodeParams(sellersId, productId);
    window.location.href = `/detail/${encodedParams}`;
}

    const [isHovered, setIsHovered] = useState(false);
  return (
<div
className="card"
style={{
  width: "100%", // Kartın genişliği %100 olacak
  borderRadius: "10px",
  border: "1px solid #ddd",
  overflow: "hidden",
  marginTop: "10px", // Üstten 10px boşluk
  boxShadow: isHovered ? "0 4px 12px rgba(0, 0, 0, 0.6)" : "none",
  transition: "box-shadow 0.3s ease-in-out",
  minHeight:"300px",
}}
onMouseEnter={() => setIsHovered(true)}
onMouseLeave={() => setIsHovered(false)}
>
<img
  src={props.image} // Resmi buraya ekleyin
  alt="Çimento"
  className="img-fluid" // Bootstrap sınıfı
  style={{
    width: "100%",
    height: "160px",
    objectFit: "contain",
  }}
/>
<div className="card-body text-center">
  <p className="card-title font-weight-bold">{props.title}</p>
  <div className="card-text text-muted">
  <p style={{maxHeight: "20px",fontSize:"13px", overflow:"hidden", textOverflow:"ellipsis", whiteSpace:"nowrap"}}>{props.variant}</p>
  <p className="item-price" style={{fontSize:"13px", padding:"2px 0", color:"black"}}> <b>{props.price}</b></p>
</div>

  <button
  onClick={handleDetailsClick}
    style={{
      padding: "6px 25px", // Butonun içindeki boşluk
      backgroundColor: "#7ac400", // Buton arka plan rengi
      color: "#fff", // Buton metin rengi
      borderRadius: "30px", // Yuvarlak köşeler
      border: "none", // Kenarlık yok
      cursor: "pointer", // İmleçin gösterimi
      opacity: isHovered ? 1 : 0, // Hover dışında görünmez
      visibility: isHovered ? "visible" : "hidden", // Hover dışında tamamen gizlenmiş
      transition: "opacity 0.3s ease, visibility 0.3s ease", // Hover'da yumuşak geçiş
      fontWeight: "500", // Metin kalınlığı
      position: "relative", // Konumlandırma için
    }}
  >
    <b>Detay</b>
  </button>
</div>



{props.product.brands && props.product.brands.name && (
            <Typography 
            variant="subtitle1" 
            className="text-center mb-3" 
            style={{ color: '#6c757d', fontSize: '1.1rem', fontStyle: 'italic' }}
        >
            {props.product.brands.name}
        </Typography>
      )}

</div>

  );
};
