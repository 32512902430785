import { useState } from "react";
import React from "react";
import 'bootstrap-icons/font/bootstrap-icons.css';
import axios from 'axios';
import config from '../config/config.json';

const initialState = {
  email: "",
  message: "",
};

export const ContactProductDetail = (props) => {
  const [{ email, message }, setState] = useState(initialState);
  const clearState = () => setState({ ...initialState });
  const apiUrl = config.ApiUrls[config.Environment];


  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // API endpoint URL
    const apiEndpoint = `${apiUrl}/api/services/app/MessagesService/Create`;

    try {
      const response = await axios.post(apiEndpoint, {
        email,
        message,
      });

      if (response.status === 200) {
        alert("Mesajınız başarıyla gönderildi.");
        clearState();
      } else {
        alert("Mesaj gönderilirken bir hata oluştu.");
      }
    } catch (error) {
      console.error("API hatası:", error);
      alert("Mesaj gönderilirken bir hata oluştu.");
    }
  };

  return (
    <>
      <div id="contact">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="section-title">
                <h2 style={{ fontSize: "1.8rem" }}><b>İLETİŞİME GEÇİN</b></h2>
                <p>
                  Görüş ve önerileriniz için aşağıdaki iletişim formunu doldurarak veya info@malzex.com adresine e-posta göndererek bizimle iletişime geçebilirsiniz.
                </p>
              </div>
              <div className="col-12 col-sm-12 col-md-8">


<form name="sentMessage" onSubmit={handleSubmit}>
                    <div className="form-group short-input mb-3">
                      <input
                        style={{
                          backgroundColor: "transparent",
                          color: "white",
                        }}
                        className="form-control placeholder-white"
                        type="email"
                        name="email"
                        value={email}
                        onChange={handleChange}
                        placeholder="Email"
                        required
                      />
                    </div>
                    <div className="form-group short-input">
                      <textarea
                        style={{
                          backgroundColor: "transparent",
                          color: "white",
                        }}
                        className="form-control placeholder-white"
                        name="message"
                        value={message}
                        onChange={handleChange}
                        placeholder="Mesaj"
                        rows="4"
                        required
                      ></textarea>
                    </div>
                    <div id="success"></div>
                    <div className="form-group d-flex justify-content-end short-input">
    <button type="submit" className="contactBtn btn btn-custom btn-lg">
      Send Message
    </button>
  </div>
                  </form>


</div>
            </div>
            <div className="col-md-4 contact-info">
              <div className="contact-item">
                <h3 style={{ fontSize: "1.3rem" }}>İletişim Bilgileri</h3>
                <p>
                  <span>
                    <i className="bi bi-geo-alt-fill" style={{ fontSize: "1rem" }}></i> Address
                  </span>
                  {props.data ? props.data.address : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="bi bi-envelope" style={{ fontSize: "1rem" }}></i> Email
                  </span>{" "}
                  {props.data ? props.data.email : "loading"}
                </p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="social">
                  <p>© 2024 All Rights Reserved.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style>
        {`
          .form-group input::placeholder,
          .form-group textarea::placeholder {
            color: white !important;
            opacity: 1;
          }

          .short-input {
            max-width: 500px; /* İstediğiniz maksimum genişlik */
          }

          .btn-custom {
            font-family: "Raleway", sans-serif;
            text-transform: uppercase;
            color: #fff;
            background-color: #5ca9fb;
            background-image: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
            padding: 14px 34px;
            letter-spacing: 1px;
            margin: 0;
            font-size: 15px;
            font-weight: 500;
            border-radius: 25px;
            transition: all 0.5s linear;
            border: 0;
          }

          .btn-custom:hover,
          .btn-custom:focus,
          .btn-custom.focus,
          .btn-custom:active,
          .btn-custom.active {
            color: #fff;
            background-image: none;
            background-color: #6372ff;
          }

          #contact .btn-custom {
            margin: 30px 0;
            background: transparent;
            border: 2px solid #fff;
          }

          #contact .btn-custom:hover {
            color: #1f386e;
            background: #fff;
          }

          #contact .form-control:focus {
            border-color: #999;
            outline: 0;
            -webkit-box-shadow: transparent;
            box-shadow: transparent;
          }

          #contact .contact-item {
            margin: 20px 0;
          }

          #contact .contact-item span {
            color: rgba(255, 255, 255, 1);
            margin-bottom: 10px;
            display: block;
          }

          #contact .contact-item i.fa {
            margin-right: 10px;
          }

          /* Contact Section */
          #contact {
            padding: 100px 0 60px;
            background: linear-gradient(to right, #424563 0%, #5ca9fb 100%);
            color: rgba(255, 255, 255, 0.75);
          }

          #contact .section-title {
            margin-bottom: 40px;
          }

          #contact .section-title p {
            font-size: 16px;
          }

          #contact h2 {
            color: #fff;
            margin-top: 10px;
            margin-bottom: 15px;
            padding-bottom: 15px;
          }

          #contact .section-title h2::after {
            position: absolute;
            content: "";
            background: rgba(255, 255, 255, 0.3);
            height: 4px;
            width: 60px;
            bottom: 0;
            left: 30px;
          }

          #contact h3 {
            color: #fff;
            margin-top: 80px;
            margin-bottom: 25px;
            padding-bottom: 20px;
            font-weight: 400;
          }

          #contact form {
            padding-top: 20px;
          }

          #contact .text-danger {
            color: #cc0033;
            text-align: left;
          }

          label {
            font-size: 12px;
            font-weight: 400;
            font-family: "Open Sans", sans-serif;
            float: left;
          }

          #contact .form-control {
            display: block;
            width: 100%;
            padding: 6px 12px;
            font-size: 16px;
            line-height: 1.42857143;
            color: #444;
            background-color: #fff;
            background-image: none;
            border: 1px solid #ddd;
            border-radius: 0;
            -webkit-box-shadow: none;
            box-shadow: none;
            -webkit-transition: none;
            -o-transition: none;
            transition: none;
          }

          #contact .social {
            border-top: 1px solid rgba(255, 255, 255, 0.15);
            padding-top: 50px;
            margin-top: 50px;
            text-align: center;
          }

          #contact .social ul li {
            display: inline-block;
            margin: 0 20px;
          }

          #contact .social i.fa {
            font-size: 22px;
            width: 48px;
            height: 48px;
            padding: 12px 0;
            border: 2px solid #fff;
            color: #fff;
            border-radius: 50%;
            transition: all 0.3s;
          }

          #contact .social i.fa:hover {
            color: #608dfd;
            background: #fff;
          }
        `}
      </style>
    </>
  );
};
