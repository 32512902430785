import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { ProductCard } from "./productCard";
import { ProductFilter } from "./productFilter";
import CircularProgress from "@mui/material/CircularProgress";
import config from '../config/config.json';

export const ListProduct = (props) => {

  const apiUrl = config.ApiUrls[config.Environment];
  const [isFirstLoad, setFirstLoad] = useState(true);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [pageSize] = useState(50);
  const [sellers, setSellers] = useState([]);
  const [products, setProducts] = useState([]);
  const [brands, setBrands] = useState([]);
  const [citys, setCitys] = useState([]);

  const [sellerFilter, setSellerFilter] = useState('');
  const [cityFilter, setCityFilter] = useState('');
  const [productFilter, setProductFilter] = useState('');
  const [brandFilter, setBrandFilter] = useState('');

  const [selectedSellers, setSelectedSellers] = useState([]);
  const [selectedCitys, setSelectedCitys] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);

  const observerRef = useRef(); // Intersection Observer için referans
  const [hasMore, setHasMore] = useState(true);

  const handleSellerChange = (id) => {
    if (selectedSellers.includes(id)) {
      setSelectedSellers(selectedSellers.filter(sellerId => sellerId !== id)); // Seçili satıcıyı kaldır
    } else {
      setSelectedSellers([...selectedSellers, id]); // Seçili satıcıyı ekle
    }
  };

  const handleCityChange = (id) => {
    if (selectedCitys.includes(id)) {
      setSelectedCitys(selectedCitys.filter(cityId => cityId !== id)); // Seçili ili kaldır
    } else {
      setSelectedCitys([...selectedCitys, id]); // Seçili ili ekle
    }
  };

  const handleBrandChange = (id) => {
    if (selectedBrands.includes(id)) {
      setSelectedBrands(selectedBrands.filter(brandId => brandId !== id)); // Seçili markayı kaldır
    } else {
      setSelectedBrands([...selectedBrands, id]); // Seçili markayı ekle
    }
  };

  const handleProductChange = (id) => {
    if (selectedProducts.includes(id)) {
      setSelectedProducts(selectedProducts.filter(productId => productId !== id)); // Seçili ürünü kaldır
    } else {
      setSelectedProducts([...selectedProducts, id]); // Seçili ürünü ekle
    }
  };

  const fetchSellers = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/services/app/SellersService/GetAll`);
      setSellers(response.data.result.data.map(seller => ({ Id: seller.id, Name: seller.name }))); 
    } catch (err) {
      setError(err.response ? err.response.data : 'Bir hata oluştu.');
    }
  };

  const fetchCitys = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/services/app/CitysService/GetAll`);
      setCitys(response.data.result.data.map(city => ({ Id: city.id, Name: city.name }))); 
    } catch (err) {
      setError(err.response ? err.response.data : 'Bir hata oluştu.');
    }
  };

  const fetchBrands = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/services/app/BrandsService/GetAll`);
      setBrands(response.data.result.data.map(brand => ({ Id: brand.id, Name: brand.name }))); 
    } catch (err) {
      setError(err.response ? err.response.data : 'Bir hata oluştu.');
    }
  };

  const fetchProducts = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/services/app/ProductsService/GetAll`);
      setProducts(response.data.result.data.map(product => ({ Id: product.id, Name: product.name }))); 
    } catch (err) {
      setError(err.response ? err.response.data : 'Bir hata oluştu.');
    }
  };

  useEffect(() => {
    fetchSellers();
    fetchProducts();
    fetchBrands();
    fetchCitys();
  }, []);
  
  // Filtrelenmiş sonuçlar
  const filteredSellers = sellers.filter(seller =>
    seller.Name.toLowerCase().includes(sellerFilter.toLowerCase())
  );

  const filteredCitys = citys.filter(city =>
    city.Name.toLowerCase().includes(cityFilter.toLowerCase())
  );
  
  const filteredBrands = brands.filter(brand =>
    brand.Name.toLowerCase().includes(brandFilter.toLowerCase())
  );
  
  const filteredProducts = products.filter(product =>
    product.Name.toLowerCase().includes(productFilter.toLowerCase())
  );

  const fetchData = async (page = 1) => {
    setLoading(true);
    try {
      const productIds = selectedProducts.length ? selectedProducts.join(",") : null;
      const sellerIds = selectedSellers.length ? selectedSellers.join(",") : null;
      const brandIds = selectedBrands.length ? selectedBrands.join(",") : null;
      const cityIds = selectedCitys.length ? selectedCitys.join(",") : null;

      const response = await axios.get(`${apiUrl}/api/services/app/ProductDetailsService/GetAll`, {
        params: {
          ProductsId: productIds || undefined,
          SellersId: sellerIds || undefined,
          BrandsId: brandIds || undefined,
          CitysId: cityIds || undefined,
          Page: page,
          PageSize: pageSize,
        },
      });

      if ((productIds !== undefined && selectedProducts.length > 0 && productIds !== null
        || sellerIds !== undefined && selectedSellers.length > 0 && sellerIds !== null
        || brandIds !== undefined && selectedBrands.length > 0 && brandIds !== null
        || cityIds !== undefined && selectedCitys.length > 0 && cityIds !== null)
        && page ==1) {
          setData(response.data.result.data);
        } else {
          // setData(prevData => [...prevData, ...response.data.result.data]);
          setData((prevData) => {
            const newData = response.data.result.data;
          
            // Duplicate kontrolü: yeni gelen verilerden, `prevData` içinde bulunmayanları filtreleyin
            const uniqueData = newData.filter(
              (newItem) => !prevData.some((prevItem) => prevItem.id === newItem.id) // `id` yerine benzersiz özellik
            );
            return [...prevData, ...uniqueData];
          });
        }

        if (response.data.result.data.length < pageSize) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
      setCurrentPage(page);
    } catch (err) {
      setError(err.response ? err.response.data : "Bir hata oluştu.");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    setFirstLoad(false);
  }, [selectedProducts, selectedSellers, selectedCitys, selectedBrands]);

  const loadMoreData = () => {
    if (hasMore && !isFirstLoad) {
      fetchData(currentPage + 1);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !loading) {
          loadMoreData();
        }
      },
      { threshold: 1.0 }
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [loading]);

  if (error) {
    return <div className="alert alert-danger text-center">{error}</div>;
  }

  if (!data.length && loading) {
    return (
      <div className="text-center" style={{fontSize:"3rem"}}><CircularProgress color="inherit" /></div>
      
    );
  }
  
  return (
    <div className="container">

      <div className="row">
        <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2">
          <ProductFilter 
            brandFilter={brandFilter} 
            setBrandFilter={setBrandFilter} 
            filteredBrands={filteredBrands} 
            selectedBrands={selectedBrands} 
            sellerFilter={sellerFilter} 
            cityFilter={cityFilter} 
            setSellerFilter={setSellerFilter} 
            setCityFilter={setCityFilter} 
            filteredSellers={filteredSellers}
            filteredCitys={filteredCitys} 
            productFilter={productFilter} 
            setProductFilter={setProductFilter} 
            filteredProducts={filteredProducts} 
            selectedSellers={selectedSellers} 
            selectedCitys={selectedCitys} 
            selectedProducts={selectedProducts}
            handleSellerChange={(id) => handleSellerChange(id)}
            handleProductChange={(id) => handleProductChange(id)}
            handleBrandChange={(id) => handleBrandChange(id)}
            handleCityChange={(id) => handleCityChange(id)}
          />
        </div>

        <div className="col-xs-12 col-sm-8 col-md-9 col-lg-10">
          <div className="row">
            {data.map((item, index) => (
              <div className="col-12 col-xs-6 col-sm-6 col-md-4 col-lg-3" key={index}>
                <ProductCard
                  title={item.products.name}
                  image={`/img/urunGorselleri/${item.imageUrl.trim()}.jpg`}
                  variant={
                    item.productPrices.length === 1
                      ? item.productPrices[0].description
                      : item.productPrices.length > 1
                      ? item.productPrices[0].description
                      : ""
                  }
                  price={
                    item.productPrices.length === 1
                      ? item.productPrices[0].price + " TL"
                      : item.productPrices.length > 1
                      ? item.productPrices[0].price + " TL"
                      : "Fiyat Bulunamadı"
                  }
                  product={item}
                />
              </div>
            ))}
          </div>

          <div ref={observerRef} style={{ height: "20px" }} className="mt-4"></div> {/* Otomatik yükleme tetikleyici */}
        </div>
        {/* <div className="col-sm-1 col-md-1">  </div> */}
      </div>
    </div>
  );
};

export default ListProduct;
