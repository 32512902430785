import React, { useState, useEffect } from "react";
import RelatedProducts from './relatedProducts';
import ProductDetailInformation from './productDetailInformation';
import JsonData from "../data/data.json";
import { ContactProductDetail } from "./contactProductDetail";
import { useParams } from 'react-router-dom';
export const ProductDetail = React.memo(({  }) => {

    const { encodedSellersId, encodedProductId , encodedProductDetailsId } = useParams();
    const sellerId = encodedSellersId ? atob(encodedSellersId) : null;
    const initialProductId = encodedProductId ? atob(encodedProductId) : null;
    const [productDetailsId, setProductDetailsId] = useState(encodedProductDetailsId ? atob(encodedProductDetailsId) : 0);

    // Ürün ID'sini yöneten state
    const [selectedProductId, setSelectedProductId] = useState(initialProductId);

    const handleProductSelect = (id) => {
      setProductDetailsId(id); // Doğrudan yeni ürün ID'sini ayarlayın
    };

    const [landingPageData, setLandingPageData] = useState({});
    useEffect(() => {
      setLandingPageData(JsonData);
    }, []);
    return (
        <>
<nav className="navbar navbar-expand-lg navbar-dark fixed-top" style={{
  background: "linear-gradient(to right, #424563 0%, #5ca9fb 100%)",
  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.15)",
  padding: "0.75rem 1rem",
}}>
  <div className="container-fluid">
    {/* Geri ikonu */}
    <button
      className="btn btn-link text-white rounded-circle p-2 hover-icon"
      onClick={() => window.history.back()}
      style={{
        color: "#ffffff",
        fontSize: "24px",
        transition: "transform 0.2s ease-in-out",
        boxShadow: "0 4px 6px rgba(0,0,0,0.2)",
        padding: "10px",
        backgroundColor: "transparent",
      }}
      onMouseOver={(e) => {
        e.target.style.transform = "scale(1.1)";
        e.target.style.backgroundColor = "rgba(255, 255, 255, 0.1)";
        e.target.style.boxShadow = "0 8px 12px rgba(0, 0, 0, 0.2)";
      }}
      onMouseOut={(e) => {
        e.target.style.transform = "scale(1)";
        e.target.style.backgroundColor = "transparent";
        e.target.style.boxShadow = "0 4px 6px rgba(0,0,0,0.2)";
      }}
    >
      <i className="bi bi-arrow-left" style={{ fontSize: "24px", color: "white" }}></i>
    </button>

    {/* Site ismi */}
    <a
      className="navbar-brand ms-3 text-white fw-bold"
      href="/"
      style={{
        fontSize: "1.30rem",
        letterSpacing: "1px",
        textTransform: "uppercase",
        color: "white",
        fontFamily: "'Arial', sans-serif",
        fontWeight: "bold",
      }}
    >
      malzex.com
    </a>
  </div>
</nav>

    <br/>
  <br/>
  <br/>
<div className="container">
    <div className="row">
        <div className="col-12 col-md-12 col-sm-12 col-lg-12">
        <ProductDetailInformation productDetailsId={productDetailsId} />
        </div>
        <div className="col-12 col-md-12 col-sm-12 col-lg-12">
        <RelatedProducts productDetailsId={productDetailsId} sellerId ={sellerId} onProductSelect={handleProductSelect} />
        </div>

    </div>
</div>
<br/>        
            <ContactProductDetail data={landingPageData.Contact} />
        </>
    );
});
export default ProductDetail;