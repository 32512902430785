import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";

export const ScrollToTop = () => {
  // Sayfanın en üstüne yavaşça kaydır
  const scrollToTop = () => {
    const scrollToTopAnimation = () => {
      // Geçerli scroll pozisyonunu al
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;

      // Eğer kaydırma pozisyonu 0'a ulaşmadıysa, yavaşça kaydırma yap
      if (currentScroll > 0) {
        window.requestAnimationFrame(scrollToTopAnimation);

        // Her adımda kaydırma miktarını azalt
        const step = Math.max(currentScroll / 5, 20);  // Her adımda 20px kadar kaydır
        document.documentElement.scrollTop = document.body.scrollTop = currentScroll - step;
      }
    };

    // Animasyonu başlat
    scrollToTopAnimation();
  };

  return (
    <button
      onClick={scrollToTop}
      style={{
        position: "fixed",
        bottom: "20px",
        right: "20px",
        backgroundColor: "#000",
        color: "#fff",
        border: "none",
        borderRadius: "50%",
        width: "50px",
        height: "50px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
        cursor: "pointer",
        zIndex: "1000",
      }}
    >
      <FontAwesomeIcon icon={faArrowUp} />
    </button>
  );
};

export default ScrollToTop;
