import React from 'react';
export const ProductFilter = ({ 
  brandFilter,
  setBrandFilter,
  filteredBrands,
  selectedBrands,
  handleBrandChange,

  handleSellerChange,
  selectedSellers,
  sellerFilter, 
  setSellerFilter, 
  filteredSellers, 


  productFilter, 
  setProductFilter, 
  filteredProducts,
  selectedProducts,
  handleProductChange,


  cityFilter, 
  handleCityChange,
  selectedCitys,
  filteredCitys,
  setCityFilter
}) => {
  return (
    <div style={{
      backgroundColor: "#f0f0f0",
      border: "1px solid #e0e0e0",
      borderRadius: "8px",
      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
      overflow: "hidden",
      marginTop: "10px"
    }} className="filter-card">
      <div style={{
        backgroundColor: "#f7f7f7",
      }} className="card-header">
        {/* <h5>Filtre</h5> */}
      </div>
      
      <div style={{
        padding: "1.5rem"
      }} className="card-body">

<div className='row'>
        {/* İl Filter */}


<div className='col-xs-12'>
        <div className='col-xs-6 col-sm-12 col-md-12 col-lg-12 d-flex flex-column'>
        <h6 style={{
          fontWeight: "bold",
          marginBottom: "0.5rem",
          fontSize: "1.1rem",
          textTransform: "uppercase",
          color: "#333",
          borderBottom: "1px solid #e0e0e0",
          paddingBottom: "5px"
        }} className="section-title">İl</h6>
        <div className="input-group">
          <span className="input-group-text">        
          </span>
          <input
            type="text"
            className="form-control search-input bi bi-search"
            placeholder="Filtrele"
            aria-label="Filtrele"
            value={cityFilter}
            onChange={e => setCityFilter(e.target.value)} // Marka filtrele
            style={{
              border: "1px solid #d0d0d0",
              borderRadius: "5px",
              padding: "0.5rem",
              transition: "border-color 0.2s"
            }}
            onFocus={(e) => e.target.style.borderColor = "#007bff"}
            onBlur={(e) => e.target.style.borderColor = "#d0d0d0"}
          />
        </div>
        <div className="brand-list" style={{
          maxHeight: "250px", // Scroll için maksimum yükseklik
          overflowY: "auto", // Dikey kaydırma çubuğu ekler
          scrollbarWidth: "thin", // Firefox için ince kaydırma çubuğu
          scrollbarColor: "linear-gradient(to right, #424563 0%, #5ca9fb 100%)" // Kaydırma çubuğunun rengi
        }}>
          {filteredCitys.map((city) => (
            <div className="form-check" key={city.Id} style={{
              display: "flex",
              alignItems: "center"
            }}>
              <input
                className="form-check-input checkbox-large"
                type="checkbox"
                id={`brandCheck${city.Id}`}
                style={{
                  width: "16px",
                  height: "13px",
                  marginRight: "2px",
                  flexShrink: 0
                }}
                checked={selectedCitys.includes(city.Id)}
                onChange={() => handleCityChange(city.Id)} 
              />
              <label className="form-check-label" htmlFor={`brandCheck${city.Id}`} style={{
                fontWeight: "bold",
                marginTop: "6px",
                fontSize: "11px",
                lineHeight: "13px",
                display: "inline-flex",
                alignItems: "center"
              }}>
                {city.Name} {/* Brand Name */}
              </label>
            </div>
          ))}
        </div>
        </div>


        {/* Satıcı Arama Girişi */}
        <div className='col-xs-6 col-sm-12 col-md-12 col-lg-12 d-flex flex-column'>
        <h6 style={{
          fontWeight: "bold",
          marginBottom: "0.5rem",
          fontSize: "1.1rem",
          textTransform: "uppercase",
          color: "#333",
          borderBottom: "1px solid #e0e0e0",
          paddingBottom: "5px"
        }} className="section-title">Satıcı</h6>
        <div className="input-group">
          <span className="input-group-text">        
          </span>
          <input
            type="text"
            className="form-control search-input bi bi-search"
            placeholder="Filtrele"
            aria-label="Filtrele"
            value={sellerFilter}
            onChange={e => setSellerFilter(e.target.value)} // Marka filtrele
            style={{
              border: "1px solid #d0d0d0",
              borderRadius: "5px",
              padding: "0.5rem",
              transition: "border-color 0.2s"
            }}
            onFocus={(e) => e.target.style.borderColor = "#007bff"}
            onBlur={(e) => e.target.style.borderColor = "#d0d0d0"}
          />
        </div>
        <div className="brand-list" style={{
          maxHeight: "250px", // Scroll için maksimum yükseklik
          overflowY: "auto", // Dikey kaydırma çubuğu ekler
          scrollbarWidth: "thin", // Firefox için ince kaydırma çubuğu
          scrollbarColor: "linear-gradient(to right, #424563 0%, #5ca9fb 100%)" // Kaydırma çubuğunun rengi
        }}>
          {filteredSellers.map((seller) => (
            <div className="form-check" key={seller.Id} style={{
              display: "flex",
              alignItems: "center"
            }}>
              <input
                className="form-check-input checkbox-large"
                type="checkbox"
                id={`brandCheck${seller.Id}`}
                style={{
                  width: "16px",
                  height: "13px",
                  marginRight: "2px",
                  flexShrink: 0
                }}
                checked={selectedSellers.includes(seller.Id)}
                onChange={() => handleSellerChange(seller.Id)} 
              />
              <label className="form-check-label" htmlFor={`brandCheck${seller.Id}`} style={{
                fontWeight: "bold",
                marginTop: "6px",
                fontSize: "11px",
                lineHeight: "13px",
                display: "inline-flex",
                alignItems: "center"
              }}>
                {seller.Name} {/* Brand Name */}
              </label>
            </div>
          ))}
        </div>
        </div>
        </div>
        <div className='col-xs-12'>
       {/* Marka Filter */}
        <div className='col-xs-6 col-sm-12 col-md-12 col-lg-12 d-flex flex-column'>
        {/* Marka Arama Girişi */}
                <h6 style={{
          fontWeight: "bold",
          marginBottom: "0.5rem",
          fontSize: "1.1rem",
          textTransform: "uppercase",
          color: "#333",
          borderBottom: "1px solid #e0e0e0",
          paddingBottom: "5px"
        }} className="section-title">Marka</h6>
        <div className="input-group">
          <span className="input-group-text">
          </span>
          <input
            type="text"
            className="form-control search-input"
            placeholder="Filtrele"
            aria-label="Filtrele"
            value={brandFilter}
            onChange={e => setBrandFilter(e.target.value)} // Marka filtrele
            style={{
              border: "1px solid #d0d0d0",
              borderRadius: "5px",
              padding: "0.5rem",
              transition: "border-color 0.2s"
            }}
            onFocus={(e) => e.target.style.borderColor = "#007bff"}
            onBlur={(e) => e.target.style.borderColor = "#d0d0d0"}
          />
        </div>

        {/* Marka Listesi */}
        <div className="brand-list" style={{
          maxHeight: "250px",
          overflowY: "auto",
          scrollbarWidth: "thin",
          scrollbarColor: "linear-gradient(to right, #424563 0%, #5ca9fb 100%)"
        }}>
          {filteredBrands.map((brand) => (
            <div className="form-check" key={brand.Id} style={{
              display: "flex",
              alignItems: "center"
            }}>
              <input
                className="form-check-input checkbox-large"
                type="checkbox"
                id={`brandCheck${brand.Id}`}
                style={{
                  width: "16px",
                  height: "13px",
                  marginRight: "2px",
                  flexShrink: 0
                }}
                checked={selectedBrands.includes(brand.Id)}
                onChange={() => handleBrandChange(brand.Id)}
              />
              <label className="form-check-label" htmlFor={`brandCheck${brand.Id}`} style={{
                fontWeight: "bold",
                marginTop: "6px",
                fontSize: "11px",
                lineHeight: "13px",
                display: "inline-flex",
                alignItems: "center"
              }}>
                {brand.Name} {/* Marka Adı */}
              </label>
            </div>
          ))}
        </div>
        </div>

          {/* Ürün Filter */}

        <div className='col-xs-6 col-sm-12 col-md-12 col-lg-12 d-flex flex-column'>
        {/* Ürünler Arama Girişi */}
        <h6 style={{
          fontWeight: "bold",
          marginBottom: "0.5rem",
          fontSize: "1.1rem",
          textTransform: "uppercase",
          color: "#333",
          borderBottom: "1px solid #e0e0e0",
          paddingBottom: "5px"
        }} className="section-title">Ürünler</h6>
        <div className="input-group">
          <span className="input-group-text">
          </span>
          <input
            type="text"
            className="form-control search-input"
            placeholder="Filtrele"
            aria-label="Filtrele"
            value={productFilter}
            onChange={e => setProductFilter(e.target.value)} // Ürün filtrele
            style={{
              border: "1px solid #d0d0d0",
              borderRadius: "5px",
              padding: "0.5rem",
              transition: "border-color 0.2s"
            }}
            onFocus={(e) => e.target.style.borderColor = "#007bff"}
            onBlur={(e) => e.target.style.borderColor = "#d0d0d0"}
          />
        </div>
        {/* Ürünler Listesi */}
        <div className="brand-list" style={{
          maxHeight: "250px",
          overflowY: "auto",
          scrollbarWidth: "thin",
          scrollbarColor: "linear-gradient(to right, #424563 0%, #5ca9fb 100%)"
        }}>
          {filteredProducts.map((product) => (
            <div className="form-check" key={product.Id} style={{
              display: "flex",
              alignItems: "center"
            }}>
              <input
                className="form-check-input checkbox-large"
                type="checkbox"
                id={`productCheck${product.Id}`}
                style={{
                  width: "16px",
                  height: "13px",
                  marginRight: "2px",
                  flexShrink: 0
                }}
                checked={selectedProducts.includes(product.Id)}
                onChange={() => handleProductChange(product.Id)}
              />
              <label className="form-check-label" htmlFor={`productCheck${product.Id}`} style={{
                fontWeight: "bold",
                marginTop: "6px",
                fontSize: "11px",
                lineHeight: "13px",
                display: "inline-flex",
                alignItems: "center"
              }}>
                {product.Name} {/* Product Name */}
              </label>
            </div>
          ))}
        </div>
        </div> 
        </div>
      </div> 
      </div>
      </div>
  );
};
