import { BrowserRouter, Routes, Route } from "react-router-dom";
import ReactDOM from "react-dom/client";
import Home from './components/home';
import Product from './components/product';
import  ProductDetail   from './components/productDetail';
import AnalyticsTracker from './utils/analyticsTracker';

const App = () => {
  return (
    <BrowserRouter>
    <AnalyticsTracker />
      <Routes>
        <Route index element={<Home />} />
        <Route path="/product" element={<Product />} />
        <Route path="/detail/:encodedSellersId/:encodedProductId/:encodedProductDetailsId" element={<ProductDetail />} />
      </Routes>
    </BrowserRouter>
  );
};
export default App;