import ReactGA from 'react-ga4';

export const initializeAnalytics = () => {
  ReactGA.initialize('G-FE7W6635DJ'); // Takip kodunuzu buraya ekleyin
};

export const logPageView = (page) => {
  ReactGA.send({ hitType: 'pageview', page });
};

export const logEvent = (category, action, label) => {
  ReactGA.event({ category, action, label });
};