import React, { useState, useEffect } from "react";
import axios from 'axios';
import CircularProgress from "@mui/material/CircularProgress";
import { Card, Tabs, Tab, Box, Typography } from '@mui/material';
import { MapComponent } from "./map";
import config from '../config/config.json';

const TabPanel = ({ children, value, index }) => {
    return (
        <div role="tabpanel" hidden={value !== index}>
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
};

const ProductDetailInformation = ({productDetailsId }) => {
    const apiUrl = config.ApiUrls[config.Environment];


    const [data, setData] = useState(null);
    const [activeTab, setActiveTab] = useState(0);

    const handleTabChange = (event, newValue) => setActiveTab(newValue);

    const fetchProductData = async () => {
        try {
            const response = await axios.get(`${apiUrl}/api/services/app/ProductDetailsService/GetDetail`, {
                params: {
                    ProductDetailsId:productDetailsId,
                }
            });
            setData(response.data.result.data[0]);
        } catch (err) {
            console.error("Error fetching product data:", err);
        }
    };

    useEffect(() => {
        if (productDetailsId) {
            fetchProductData();
        }
    }, [productDetailsId]);

    if (!data) {
        return (
          <div
            style={{
              position: 'fixed',      // Sayfada sabit kalmasını sağlar
              top: '10%',             // Dikeyde tam ortalama
              left: '50%',            // Yatayda tam ortalama
              transform: 'translate(-50%, -50%)', // Gerçekten ortalamak için
              display: 'flex',        // Flexbox ile içerikleri yerleştireceğiz
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',        // Yükseklik ekranın tamamı
              width: '100%',          // Genişlik ekranın tamamı
            }}
          >
            <CircularProgress color="inherit" style={{ fontSize: "3rem" }} />
          </div>
        );
      }
      
    import('bootstrap/dist/css/bootstrap.min.css');
    return (
        <div className="productDetail container" style={{ overflowX: 'hidden', fontFamily: 'Open Sans' }}>
            {/* Ürün Ana Detayları */}
            <Card variant="outlined" className="mt-5 p-4 rounded-3 border-0" style={{ boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)' }}>
    <div className="row">
        {/* Image Section */}
        <div className="col-md-6 d-flex justify-content-center align-items-center mb-4 mb-md-0">
            <img 
                src={`/img/urunGorselleri/${data.imageUrl.trim()}.jpg`}
                className="img-fluid rounded" 
                alt={data.products.name} 
                style={{ maxWidth: '90%', borderRadius: '8px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.12)' }}
            />
        </div>
        
        {/* Content Section */}
        <div className="col-md-6">
            <Typography 
                variant="h5" 
                className="text-uppercase mb-1 text-center" 
                style={{ color: '#343a40', fontWeight: 'bold' }}
            >
                {data.products.name}
            </Typography>

            {/* Brand as a Subtitle */}
            <Typography 
                variant="subtitle1" 
                className="text-center mb-3" 
                style={{ color: '#6c757d', fontSize: '0.9rem', fontStyle: 'italic' }}
            >
                {data.brands.name}
            </Typography>

            <Typography 
                className="mb-4 text-center text-md-start" 
                style={{ lineHeight: '1.6', color: '#6c757d', fontSize: '1rem' }}
            >
                {data.products.description}
            </Typography>

            {/* Pricing Section */}
            <div className="price-container mb-3">
                <Typography 
                    variant="h6" 
                    className="price text-center text-md-start" 
                    style={{ fontSize: '1.1rem', color: '#333', borderBottom: '2px solid #ddd', paddingBottom: '10px', marginBottom: '20px' }}
                >
                    <b>Fiyatlar</b> 
                </Typography>
                <div className="d-flex justify-content-around flex-wrap">
                    {data.productPrices.map((priceInfo, index) => (
                        <div 
                            key={index} 
                            className="price-box bg-light rounded-3 p-3 text-center mx-2 mb-3" 
                            style={{ width: '120px', boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.1)' }}
                        >
                            <Typography 
                                style={{ color: '#6c757d', fontSize: '0.9rem', fontWeight: '500' }}
                            >
                                {priceInfo.description}
                            </Typography>
                            <Typography 
                                style={{ fontWeight: 'bold', color: '#28a745', fontSize: '1.1rem' }}
                            >
                                {priceInfo.price} TL
                            </Typography>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </div>
</Card>





    {/* Satıcı Bilgileri */}
            <Card variant="outlined" className="mt-4 shadow-sm p-4">
    <Tabs
        value={activeTab}
        onChange={handleTabChange}
        textColor="primary"
        indicatorColor="primary"
        variant="fullWidth"
        sx={{
            '& .MuiTab-root': { color: 'black', fontWeight: 'bold' },
            '& .Mui-selected': { color: 'black', borderBottom: '3px solid blue' }
        }}
    >
        <Tab label="Satıcı Bilgileri" />
    </Tabs>


    <TabPanel value={activeTab} index={0}>
        <div className="seller-info">
            {/* Satıcı Adı ve Logo */}
            <div className="d-flex align-items-center mb-3">
                <img             
                    src={`/img/saticiGorselleri/${data.sellers.imageUrl.trim()}.jpg`}
                    style={{ width: '50px', height: '50px', objectFit: 'contain', marginRight: '15px' }}
                />
                <Typography variant="h6" component="b" style={{ fontSize: '1.2rem', color: '#333' }}>
                    {data.sellers.name}
                </Typography>
            </div>

            {/* Satıcı Bilgileri */}
            <ul>
                <li style={{ fontSize: '0.9rem', color: '#777' }}>TSE damgalı ürünler satılmaktadır.</li>
                <li style={{ fontSize: '0.9rem', color: '#777' }}>
                    Bölge dışı satışlar için satıcı ile irtibata geçiniz.
                </li>
                <li style={{ fontSize: '0.9rem', color: '#777' }}>
                    Toplu alımlarda <b>iskonto</b> oranları değişiklik gösterebilir. Detaylı bilgi için satıcıyla iletişime
                    geçiniz.
                </li>
            </ul>

            {/* Satıcı Adresi */}
            <Typography variant="body1" paragraph style={{ fontSize: '0.9rem', color: '#777' }}>
                <b>Adres:</b> {data.sellers.address || 'Adres bilgisi mevcut değil'}
            </Typography>

            {/* Satıcı İletişim Bilgileri */}
            <Typography variant="body1" paragraph style={{ fontSize: '0.9rem', color: '#777' }}>
                <b>İletişim:</b> {data.sellers.telephone || 'İletişim bilgisi mevcut değil'}
            </Typography>
        </div>

        {/* Harita Alt Kısımda */}
        <div style={{ marginTop: '20px' }}>
            <MapComponent data={data} />
        </div>
    </TabPanel>
</Card>
        </div>
    );
};

export default ProductDetailInformation;
