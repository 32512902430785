import React, { useState, useEffect } from "react";
import axios from 'axios';
import config from '../config/config.json';

const RelatedProducts = ({ productDetailsId, sellerId, onProductSelect }) => {
    const apiUrl = config.ApiUrls[config.Environment];


  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const handleDetailsClick = (product) => {  
debugger;

  sellerId = product.sellersId;
  onProductSelect(product.id)
  };


  useEffect(() => {
    const fetchData = async () => {
        try {
            const response = await axios.get(`${apiUrl}/api/services/app/ProductDetailsService/GetSellerOtherProducts/${sellerId}/${productDetailsId}`);
            setData(response.data.result.data);
        } catch (err) {
            setError(err.response ? err.response.data : 'An error occurred');
        }
    };

    fetchData();
}, [productDetailsId]);

if (!data) {
  return (
    <div></div>
    
  );
}

    import('bootstrap/dist/css/bootstrap.min.css');
    import('bootstrap/dist/js/bootstrap.bundle.min.js');
  return (
    <>
    <div className="relatedProducts"></div>
      <div className="relatedProductsContainer container">
        <div className="row">
          <div className="col-md-12">
            <h2 className="relatedProductTitle">Satıcının diğer  <b>Ürünleri</b></h2>
            <div id="featuredProductsCarousel" className="carousel slide" data-bs-ride="false" data-bs-interval="false">
              <div className="carousel-indicators">
                <button type="button" data-bs-target="#featuredProductsCarousel" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#featuredProductsCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="row">
                  {data && data && data.length > 0 ? (
            data.slice(0, 4).map((product) => (
                <div className="col-sm-3" key={product.id}>
                    <div className="thumb-wrapper">
                        <div className="img-box">
                            <img
                                src={`/img/urunGorselleri/${product.imageUrl.trim()}.jpg`}
                                className="img-fluid"
                                alt={product.products?.name || 'Ürün resmi'}
                                style={{minHeight:'150px', maxHeight:'150px',objectFit: 'contain'}}
                            />
                        </div>
                        <div className="thumb-content">
                            <h4>{product.products?.name || 'Ürün adı bulunamadı'}</h4>
                            <p style={{maxHeight: "20px",fontSize:"13px", overflow:"hidden", textOverflow:"ellipsis", whiteSpace:"nowrap"}}>{product.productPrices[0].description}</p>
                            <p className="item-price">
                                <b>
                                    {product.productPrices.length === 1
                                        ? `${product.productPrices[0].price} TL`
                                        : product.productPrices.length > 1
                                        ? `${product.productPrices[0].price} TL`
                                        : 'Fiyat bulunamadı'}
                                </b>
                            </p>
                            <button onClick={() => handleDetailsClick(product)} className="btn btn-primary">Detay</button>
                        </div>
                    </div>
                </div>
            ))
        ) : (
            <div className="text-center">Ürün bulunamadı.</div> // Eğer data boşsa bu mesajı göster
        )}
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="row">
                  {data && data && data.length > 0 ? (
            data.slice(4, 8).map((product) => (
                <div className="col-sm-3" key={product.id}>
                    <div className="thumb-wrapper">
                        <div className="img-box">
                            <img
                                src={`/img/urunGorselleri/${product.imageUrl.trim()}.jpg`}
                                className="img-fluid"
                                alt={product.products?.name || 'Ürün resmi'}                              
                                style={{minHeight:'150px', maxHeight:'150px',objectFit: 'contain'}}
                            />
                        </div>
                        <div className="thumb-content">
                            <h4>{product.products?.name || 'Ürün adı bulunamadı'}</h4>
                            <p style={{maxHeight: "20px",fontSize:"13px", overflow:"hidden", textOverflow:"ellipsis", whiteSpace:"nowrap"}}>{product.productPrices[0].description}</p>
                            <p className="item-price">
                                <b>
                                    {product.productPrices.length === 1
                                        ? `${product.productPrices[0].price} TL`
                                        : product.productPrices.length > 1
                                        ? `${product.productPrices[0].price} TL`
                                        : 'Fiyat bulunamadı'}
                                </b>
                            </p>
                            <button onClick={() => handleDetailsClick(product)} className="btn btn-primary">Detay</button>
                        </div>
                    </div>
                </div>
            ))
        ) : (
            <div className="text-center">Ürün bulunamadı.</div> // Eğer data boşsa bu mesajı göster
        )}
                  </div>
                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#featuredProductsCarousel" data-bs-slide="prev">
                <span className="carousel-control-prev-icon"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#featuredProductsCarousel" data-bs-slide="next">
                <span className="carousel-control-next-icon"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <style>
        {`
  .relatedProducts{
  background-color: #00FFFF;
  }


            .relatedProductTitle {
                color: #000;
                font-size: 26px;
                font-weight: 300;
                text-align: center;
                text-transform: uppercase;
                position: relative;
                margin: 30px 0 60px;
            }
            .relatedProductTitle::after {
                content: "";
                width: 100px;
                position: absolute;
                margin: 0 auto;
                height: 4px;
                border-radius: 1px;
                background: #7ac400;
                left: 0;
                right: 0;
                bottom: -20px;
            }
            .carousel {
                margin: 50px auto;
                padding: 0 70px;             
            }
            .carousel .item {
                color: #747d89;
                min-height: 325px;
                text-align: center;
                overflow: hidden;
            }
            .carousel .thumb-wrapper {
                padding: 25px 15px;
                background: #fff;
                border-radius: 6px;
                text-align: center;
                position: relative;
                box-shadow: 0 2px 3px rgba(0,0,0,0.2);
            }
            .carousel .item .img-box {
                height: 120px;
                margin-bottom: 20px;
                width: 100%;
                position: relative;
            }
            .carousel .item img {    
                max-width: 100%;
                max-height: 100%;
                display: inline-block;
                position: absolute;
                bottom: 0;
                margin: 0 auto;
                left: 0;
                right: 0;
            }
            .carousel .item h4 {
                font-size: 18px;
            }
            .carousel .item h4, .carousel .item p, .carousel .item ul {
                margin-bottom: 5px;
            }
            .carousel .thumb-content .btn {
                color: #7ac400;
                font-size: 11px;
                text-transform: uppercase;
                font-weight: bold;
                background: none;
                border: 1px solid #7ac400;
                padding: 6px 14px;
                margin-top: 5px;
                line-height: 16px;
                border-radius: 20px;
            }
            .carousel .thumb-content .btn:hover, .carousel .thumb-content .btn:focus {
                color: #fff;
                background: #7ac400;
                box-shadow: none;
            }
            .carousel .thumb-content .btn i {
                font-size: 14px;
                font-weight: bold;
                margin-left: 5px;
            }
            .carousel .item-price {
                font-size: 13px;
                padding: 2px 0;
            }
            .carousel-control-prev, .carousel-control-next {
                height: 44px;
                width: 40px;
                background: #7ac400;    
                margin: auto 0;
                border-radius: 4px;
                opacity: 0.7;
                transition: opacity 0.2s;
            }
            .carousel-control-prev:hover, .carousel-control-next:hover {
                opacity: 1;
            }
        `}
      </style>
    </>
  );
};

export default RelatedProducts;
