import React, { useState, useEffect } from "react";

export const Header = (props) => {
  const [backgroundImage, setBackgroundImage] = useState("/img/intro-bg.jpg");
  const images = ["/img/intro-bg.jpg", "/img/intro-bg1.jpg", "/img/intro-bg2.jpg", "/img/intro-bg3.jpg"];
  let currentImageIndex = 0;

  useEffect(() => {
    const interval = setInterval(() => {
      currentImageIndex = (currentImageIndex + 1) % images.length;
      setBackgroundImage(images[currentImageIndex]);
    }, 5000); // Her 3 saniyede bir arka plan fotoğrafı değişir
    return () => clearInterval(interval); // Bileşen unmount olduğunda intervali temizler
  }, []);

  return (
    <header id="header">
      <div className="intro" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="intro-text">
                <h1>
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h1>
                <p>{props.data ? props.data.paragraph : "Loading"}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
