import React from 'react';
import ReactDOM from 'react-dom/client';  // React 18'de react-dom/client kullanıyoruz
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { initializeAnalytics } from './utils/analytics';

initializeAnalytics();

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

root.render(
    <App />
);
serviceWorker.unregister();
